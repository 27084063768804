import { CommunityAppBarProps } from "@/community/CommunityAppBar"
import { useLabel } from "@/core/context/LabelsContext"
import { useStreamChat } from "@/core/context/StreamChatContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import { isProductsTabRoute } from "@/core/route/util/routeUtils"
import GlobalNavButton from "@/main/page/global-nav/GlobalNavButton"
import { DiscoIcon } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"

type ProductsButtonProps = TestIDProps & Pick<CommunityAppBarProps, "variant">

export default function ProductsButton(props: ProductsButtonProps) {
  const { testid = "ProductsButton", variant } = props
  const productLabel = useLabel("sidebar_product")
  const isActive = isProductsTabRoute(location.pathname)
  const { productMemberChannels } = useStreamChat()

  return (
    <GlobalNavButton
      testid={testid}
      to={ROUTE_NAMES.COMMUNITY.EXPERIENCES.COURSES.UPCOMING}
      tooltip={productLabel.singular}
      isActive={isActive}
      variant={variant}
      notificationConfig={{
        filter: {
          productsOnly: true,
          kinds: [
            "assignment-submitted",
            "course-content-publish",
            "quiz-submitted",
            "survey-submitted",
          ],
        },
        streamChannelIds: productMemberChannels.map((c) => c.externalChannelId),
      }}
    >
      <DiscoIcon icon={"book"} active={isActive} />
    </GlobalNavButton>
  )
}
